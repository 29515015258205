// UploadModal.jsx

import { useState } from 'react';
import { toast } from 'react-toastify';
import { twJoin, twMerge } from 'tailwind-merge';
import { DefaultModal } from './DefaultModal';

const SOURCE_TYPE = {
  FILE_UPLOAD: 'FILE_UPLOAD',
  URL: 'EURLEX_URL',
};

export const UploadModal = ({ handleFileUpload, setShowModal, isLoading, isVisible, onUploadEvent }) => {
  const [dragging, setDragging] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [sourceType, setSourceType] = useState(SOURCE_TYPE.FILE_UPLOAD);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSourceTypeChange = (event) => {
    setSourceType(event.target.value);
    setUploadedFileName('');
    setFile(null);
    setFileName('');
    setErrorMessage('');
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    setFileName('');
    setErrorMessage('');
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      if (selectedFile) {
        setUploadedFileName(selectedFile.name);
        setLoading(true);
        // Validar el archivo aquí si es necesario
        setFile(selectedFile);
        setFileName(selectedFile.name);
        setLoading(false);
      } else {
        setErrorMessage('Documento incompatible!');
      }
    }
  };

  const handleFileUploadWithLoading = (event) => {
    setErrorMessage('');
    setFileName('');
    setLoading(true);
    const selectedFile = event.target.files ? event.target.files[0] : null;
    if (selectedFile) {
      setFile(selectedFile);
      setUploadedFileName(selectedFile.name);
      setFileName(selectedFile.name);
      setLoading(false);
    } else {
      setLoading(false);
      setErrorMessage('Documento incompatible!');
    }
  };

  const handleURLChange = (e) => {
    const url = e.target.value;
    setUploadedFileName(url);
    setFile(url);
  };

  const handleProcess = async () => {
    setErrorMessage('');
    if (!uploadedFileName) {
      setErrorMessage('Por favor, seleccione un archivo o ingrese una URL.');
      return;
    }

    setLoading(true);

    await handleFileUpload(
      sourceType === SOURCE_TYPE.FILE_UPLOAD ? file : uploadedFileName,
      sourceType,
      (response, data) => {
        if (onUploadEvent) {
          onUploadEvent(response, data);
        }

        if (response === 'processing_complete') {
          setLoading(false);
          setIsProcessing(false);
          toast.success('File was successfully processed')
          setShowModal(false);
        } else if (response === 'processing_error') {
          setLoading(false);
          setIsProcessing(false);
          toast.error('Error processing the file. Please, try again.');
          setErrorMessage('Error processing.');
        }
      }
    );
  };

  const isProcessDisabled =
    (sourceType === SOURCE_TYPE.FILE_UPLOAD && !file) ||
    (sourceType === SOURCE_TYPE.URL && !uploadedFileName);

  return (
    <DefaultModal
      className={twMerge(sourceType === SOURCE_TYPE.FILE_UPLOAD ? 'h-72' : 'h-48')}
      isVisible={isVisible}
      title="Content Upload"
      onClose={() => setShowModal(false)}
      onSubmit={handleProcess}
      isDisabled={isProcessDisabled || isLoading || loading || isProcessing}
      isLoading={isLoading || loading || isProcessing}
    >
      {!isProcessing ? (
        <div
          className={twJoin(
            'flex justify-between gap-6',
            sourceType === SOURCE_TYPE.FILE_UPLOAD ? 'items-center' : 'items-end'
          )}
          onDragEnter={sourceType === SOURCE_TYPE.FILE_UPLOAD ? handleDragEnter : undefined}
          onDragOver={sourceType === SOURCE_TYPE.FILE_UPLOAD ? handleDragOver : undefined}
          onDragLeave={sourceType === SOURCE_TYPE.FILE_UPLOAD ? handleDragLeave : undefined}
          onDrop={sourceType === SOURCE_TYPE.FILE_UPLOAD ? handleDrop : undefined}
        >
          {sourceType === SOURCE_TYPE.FILE_UPLOAD ? (
            <label
              htmlFor="dropzone-file"
              className={`flex h-36 cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-gray-300 bg-blue-50 ${dragging ? 'bg-blue-100' : ''
                }`}
            >
              <div className="flex flex-col items-center justify-center">
                <svg
                  className="mb-4 h-8 w-8 text-gray-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <div className="small align-content-center w-[400px] justify-center text-center text-gray-500 ">
                  {fileName !== '' ? (
                    <p className="wrapped-text">{fileName}</p>
                  ) : (
                    <p>
                      <span className="font-semibold">Click here to upload</span>
                    </p>
                  )}
                </div>
              </div>
              <input
                onChange={handleFileUploadWithLoading}
                id="dropzone-file"
                type="file"
                className="hidden"
              />
            </label>
          ) : (
            <input
              type="text"
              placeholder="Enter the URL to start processing"
              className="h-7 w-full flex-1 rounded-md border border-gray-300 indent-2 text-xs"
              value={uploadedFileName}
              onChange={handleURLChange}
            />
          )}
          <div className="w-44">
            <h1 className="big text-gray-600">Source type</h1>
            <select
              className="small mt-2 w-full cursor-pointer rounded-md border border-gray-300 p-1 text-gray-500"
              value={sourceType}
              onChange={handleSourceTypeChange}
            >
              <option value={SOURCE_TYPE.FILE_UPLOAD}>File</option>
              <option value={SOURCE_TYPE.URL}>URL</option>
            </select>
          </div>
        </div>
      ) : (
        <div className="flex grow flex-col items-center justify-center">
          <h1 className="text-lg font-bold">Processing...</h1>
          <div className="mt-4 w-16 h-16 border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
        </div>
      )}
      {errorMessage && <p className="mt-2 text-red-500">{errorMessage}</p>}
    </DefaultModal>
  );
};
