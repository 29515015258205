// import { ProfileMenu } from 'layouts/components/Sidebar/components/ProfileMenu'
import { getStagingEnvironment } from 'utils/getStagingEnvironment'
import { Tag } from '../Tag/Tag'

const stagingEnv = getStagingEnvironment()

export const Header = ({ heading, icon }) => (
  <div className="flex justify-between">
    <div className="mx-2 mb-4 flex items-center gap-2 text-xl ">
      <img className="h-6" alt={heading} src={icon} />
      <h1 className="font-bold">{heading}</h1>
    </div>
    <div className="mb-4 flex items-center gap-2">
      {stagingEnv && <Tag content={stagingEnv} />}
      {/* <ProfileMenu /> */}
    </div>
  </div>
)
