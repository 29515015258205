import { postLoop } from 'api/loop/handler'
import { addData, addOutputData, setIsLoading, setPayload } from 'app/features/loopSlice'
import { GenerateButton } from 'components/shared/Buttons'
import { Card } from 'components/shared/Card/Card'
import { Select, Textarea } from 'components/shared/Inputs'
import { DarkSpinner } from 'components/shared/Loaders'
import { ReactTooltip } from 'components/shared/Tooltip/ReactTooltip'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'


export const Form = () => {
  const dispatch = useDispatch()
  const { register, handleSubmit } = useForm()
  const { qntyFiles, files, selectedFiles } = useSelector(({ files }) => files)
  const { isLoading, config } = useSelector(({ loop }) => loop)

  const FORM_SELECT_INPUTS = {
    goal: {
      label: 'Goal',
      iconSrc: '/icons/question.png',
      iconInfoTooltip: 'The overall purpose for processing',
      options: config?.goal?.map((item) => ({ label: item.value, value: item.key }))
    },
    length: {
      label: 'Length',
      iconSrc: '/icons/question.png',
      iconInfoTooltip: 'The approximate length of the result. It may vary depending on the length of the original document.',
      options: config?.length?.map((item) => ({ label: item.value, value: item.key }))
    },
    format: {
      label: 'Format',
      iconSrc: '/icons/question.png',
      iconInfoTooltip: 'The format in which the results are presented.',
      options: config?.result_format?.map((item) => ({ label: item.value, value: item.key }))
    },
    language: {
      label: 'Language',
      iconSrc: '/icons/question.png',
      iconInfoTooltip: 'The language in which the results are presented.',
      options: config?.language?.map((item) => ({ label: item.value, value: item.key }))
    }
  }

  const onSubmit = async (data) => {
    dispatch(setPayload(data))
    dispatch(setIsLoading(true))
    const response = await dispatch(postLoop({ ...data, document: files[selectedFiles] }))

    if (response.status !== 201) {
      toast.error('Something went wrong')
    } else {
      dispatch(addData(response.data))
      const token = JSON.parse(localStorage.getItem('access_token'))
      const wsScheme = window.location.protocol === 'https:' ? 'wss' : 'ws'
      const wsPath = `/ws/${response.data.id}/process`
      const wsUrl = `${wsScheme}://${process.env.REACT_APP_WS_URL}${wsPath}?access_token=${token}`
      const socket = new WebSocket(wsUrl)

      const data = JSON.stringify({ pk: response.data.id })
      socket.onopen = () => {
        socket.send(data)
      }
      socket.onmessage = (event) => {
        const responseProcessedLoop = JSON.parse(event.data)
        if (responseProcessedLoop.result) {
          dispatch(addOutputData(responseProcessedLoop))
          toast.success('File processed successfully')
          dispatch(setIsLoading(false))
          socket.close()
        } else {
          toast.error('Something went wrong during processing')
        }
      }
      socket.onerror = () => {
        toast.error('WebSocket connection error')
      }
    }
  }

  return (
    <>
      {isLoading ? (
        <DarkSpinner />
      ) : (
        <div className="pb-5">
          <Card>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="flex flex-col gap-8 text-sm">
                <div className="flex grow flex-col gap-5">
                  {Object.entries(FORM_SELECT_INPUTS).map(([key, value]) => (
                    <Select key={key} {...value} register={register(key)} />
                  ))}
                  <Textarea
                    label="Attention"
                    iconSrc="/icons/question.png"
                    iconInfoTooltip="This field is used to specify to the AI what it has to pay special attention to when processing the document and generating the results."
                    register={register('attention')}
                    className="max-h-24"
                  />
                </div>

                <div className="flex justify-end">
                  <ReactTooltip
                    enabled={qntyFiles !== 1}
                    tooltipContent={
                      qntyFiles === 0
                        ? 'You must select a file from the sidebar content list'
                        : 'You must select only one file'
                    }
                  >
                    <GenerateButton isDisabled={qntyFiles !== 1} />
                  </ReactTooltip>
                </div>
              </div>
            </form>
          </Card>
        </div>
      )}
    </>
  )
}
