import { cleanSelectedFiles } from 'app/features/fileSlice'
import { resetLoop } from 'app/features/loopSlice'
import { resetmirrorState, setStop } from 'app/features/mirrorSlice'
import { resetsmartchatState } from 'app/features/smartChatSlice'
import { DefaultModal } from 'components/shared/Modal/DefaultModal'
import { ReactTooltip } from 'components/shared/Tooltip/ReactTooltip'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

export const CleanDataModal = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [showConfirmation, setShowConfirmation] = useState(false)

  const handleReset = () => {
    const { pathname } = location
    if (pathname.includes('/loop')) {
      dispatch(resetLoop())
    } else if (pathname.includes('/mirror')) {
      dispatch(resetmirrorState())
      dispatch(setStop(true))
    } else if (pathname.includes('/assistant')) {
      dispatch(resetsmartchatState())
    }
    dispatch(cleanSelectedFiles())
    setShowConfirmation(false)
  }

  return (
    <>
      <ReactTooltip tooltipContent="Clear workspace" className="flex">
        <button onClick={() => setShowConfirmation(true)}>
          <img className="size-6" src="/icons/refresh-right-square.png" alt="reset" />
        </button>
      </ReactTooltip>

      <DefaultModal
        title="Clear workspace"
        isVisible={showConfirmation}
        onSubmit={handleReset}
        onClose={() => setShowConfirmation(false)}
        size="s"
      >
        <div className="flex gap-2">
          <img className="size-10" src="/icons/warning.png" alt="warning" />
          <p>This action will clear your workspace. Do you want to continue?</p>
        </div>
      </DefaultModal>
    </>
  )
}
