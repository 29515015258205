// import { getProfileAction } from 'api/Login/action/action'
import { /* useEffect,  */ useRef } from 'react'
// import { useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { Sidebar } from './components/Sidebar/Sidebar'

export default function Layout() {
  // const dispatch = useDispatch()

  // Add a ref to the scroll container so that scrollbar remains in the same position
  const scrollContainerRef = useRef(null)

  /*   useEffect(() => {
      dispatch(getProfileAction())
    }, [])
   */
  return (
    <div className="flex h-screen w-screen bg-neutral-100">
      <Sidebar />
      <div className="h-full w-full overflow-auto px-4 py-5" ref={scrollContainerRef}>
        <Outlet context={{ scrollContainerRef }} />
      </div>
    </div>
  )
}
